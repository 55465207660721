import React from 'react';
import Layout from '../../../Components/Layout/Layout';
import './Recent.scss';
import { graphql, useStaticQuery } from 'gatsby';

/**
 * @namespace Recent
 * @function Recent
 * @author Trevor Cash
 * @since 11/06/21
 * @version 1.0.0
 * @component
 */
export default function Recent() {
  const data = useStaticQuery(graphql`
    query recentQuery {
      allSanityHome {
        nodes {
          latest
        }
      }
    }
  `);
  console.log(data?.allSanityHome?.nodes?.[0]);
  const id = data?.allSanityHome?.nodes?.[0]?.latest;
  return (
    <Layout
      className='LatestSermon-Container'
      title='Recent'
      description='Find the latest recording of our Sunday worship here.'
    >
      <h1>Latest Message</h1>
      <div className='sermon-embed-player'>
        {id && (
          <iframe
            title='Latest Stream'
            src={`https://fbcwjohio.sermon.net/player/video/${id}?
repeat=false&autostart=true`}
            frameBorder='0'
            width='100%'
            height='100%'
            scrolling='no'
            allowtransparency='true'
            allowFullScreen
          ></iframe>
        )}
      </div>
    </Layout>
  );
}
